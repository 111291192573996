




























































































































































































































































































































































.dialogBox {
  height: 600px;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 0;
    h3 {
      padding-bottom: 0.75rem;
    }
  }
  .divBox2 {
    width: 100%;
  }
}
tr {
  height: 32px;
}
