.dialogBox {
  height: 600px;
}
.dialogBox .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem 0;
}
.dialogBox .title h3 {
  padding-bottom: 0.75rem;
}
.dialogBox .divBox2 {
  width: 100%;
}
tr {
  height: 32px;
}
